<template>
  <div id="china_map_box">
    <div id="china_map" />
  </div>
</template>

<script>
import echarts from "echarts";
import resize from "./mixins/resize";
import "echarts/map/js/china.js";
import { replaceChars } from "@/utils";
import { EventBus } from "@/utils/EventBus";
require("echarts/theme/macarons"); // echarts theme

export default {
  mixins: [resize],
  props: {
    formData: {
      type: Array,
      default: () => [],
    },
    isNationwide: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      myChart: null,
      options: "",
      dataResource: [],
      dataScourceProvince: [
        "省",
        "市",
        "特别行政区",
        "自治区",
        "回族",
        "壮族",
        "维吾尔",
      ], // 省份名称与pinyin对应
      tooltipInterval: null, // 自动轮播定时器
      currentIndex: 0, // 监测当前地图位置下标
    };
  },
  created() {
    const rjs = require.context("echarts/map/js/province");
    rjs.keys().forEach(rjs);
  },
  mounted() {
    this.$nextTick(() => {
      this.initEchartMap();
    });
    EventBus.$on("apiDataMapEvent", (e) => {
      this.stopTooltipRotation();

      if (e.regionName === "全国") {
        this.options.geo.map = "china";
      } else {
        this.options.geo.map = replaceChars(
          e.regionName,
          this.dataScourceProvince
        );
      }
      this.dataResource = []; // 初始化数据
      // 更新数据
      e.data.organization.forEach((item) => {
        const name = this.isNationwide
          ? replaceChars(item.regionName, this.dataScourceProvince)
          : item.regionName;
        this.dataResource.push({
          name: name,
          value: item.organizationCount ? parseInt(item.organizationCount) : 0,
          ...item,
        });
      });

      if (this.options.geo.map === "海南") {
        this.options.geo.zoom = 8;
        this.options.geo.center = [109.5, 19.3];
      } else {
        this.options.geo.zoom = 1.2;
        this.options.geo.center = null;
      }

      this.options.series[0].data = this.dataResource;
      this.myChart.clear();
      this.myChart.setOption(this.options, true);
      this.startTooltipRotation();
    });
  },
  beforeDestroy() {
    EventBus.$off("apiDataMapEvent");
    if (this.tooltipInterval) {
      this.stopTooltipRotation();
    }
    if (this.myChart) {
      this.myChart.dispose();
    }
  },
  methods: {
    // 初始化中国地图
    initEchartMap() {
      let that = this;
      const mapDiv = document.getElementById("china_map");
      // 生成地图并使用本地Json地图数据
      this.myChart = echarts.init(mapDiv);
      this.dataResource = [];
      this.formData.forEach((item) => {
        const name = replaceChars(item.regionName, this.dataScourceProvince);
        this.dataResource.push({
          name: name,
          value: item.organizationCount ? parseInt(item.organizationCount) : 0,
          ...item,
        });
      });

      // echart 配制option
      this.options = {
        tooltip: {
          trigger: "item",
          showDelay: 0, // 显示延迟，提示框即时显示
          transitionDuration: 0, // 动画时间，提示框即时显示
          padding: 12,
          borderWidth: 1,
          borderColor: "#00FDFE",
          backgroundColor: "rgba(20,30,39,0.7)",
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },

          formatter: function (e) {
            const data = e.data;
            // 判断没有值的区域 不显示数据
            if (!e.value) {
              return;
            }

            return `
                 <div>
                     <p><b style="font-size:15px;">${data.name}</b></p>
                     <p class="tooltip_style"><span class="tooltip_left">养老机构数量</span><span class="tooltip_right">${data.organizationCount}</span></p>
                     <p class="tooltip_style"><span class="tooltip_left">民政用户数 </span><span class="tooltip_right">${data.civilUserCount}</span>
                 </div>
              `;
          },
        },
        geo: {
          map: "china",
          zoom: 1.2,
          top: 60,
          center: null,
          label: {
            normal: {
              show: true,
              fontSize: 12,
              color: "#fff",
            },
          },
          itemStyle: {
            normal: {
              borderColor: "#00FDFE",
              areaColor: "#141e27",
            },
          },
          emphasis: {
            label: {
              show: true,
              color: "#fff", // 高亮状态下的标签文字颜色
            },
            itemStyle: {
              areaColor: "#00FDFE",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              borderWidth: 0,
            },
          },
          regions: this.dataResource.map((item) => {
            return {
              name: item.name,
              itemStyle: {
                normal: {
                  areaColor: "#7D68EF",
                },
              },
            };
          }),
        },
        series: [
          {
            name: "养老通数据看板",
            type: "map",
            map: "china",
            geoIndex: 0,
            data: this.dataResource,
          },
        ],
      };
      this.myChart.setOption(that.options);

      // 开始自动轮播提示框
      this.startTooltipRotation();

      // 监听鼠标移入和移出事件
      this.myChart.on("mouseover", this.stopTooltipRotation);
      this.myChart.on("mouseout", this.startTooltipRotation);
      // 点击监听 监听是否跳转到二级页面
      this.myChart.on("click", function (params) {
        if (params.seriesType === "map") {
          console.log("点击了地图");
          // 下钻逻辑
          // 例如，当点击北京时，更新为北京市的地图
          if (that.isNationwide) {
            that.options.geo.map = params.name;
            // 选择海南时修改缩放比例与位置
            if (params.name === "海南") {
              that.options.geo.zoom = 8;
              that.options.geo.center = [109.5, 19.3];
            } else {
              that.options.geo.zoom = 1.2;
              that.options.geo.center = null;
            }
            that.$emit("onChangeRegion", params.data);
            // this.myChart.setOption(that.options, true);
          }
        }
      });
    },
    // 提示框 自动轮播
    startTooltipRotation() {
      if (this.tooltipInterval) {
        return; // 如果定时器已经存在，则不重复创建
      }
      this.currentIndex = 0;
      const data = this.dataResource; // 获取地图数据

      this.tooltipInterval = setInterval(() => {
        this.highlightArea(this.currentIndex, data);
      }, 3000); // 设置轮播间隔，这里为2秒
    },
    // 提示框 停止自动轮播
    stopTooltipRotation(params) {
      // 当地图没有数据时 地图不高亮
      if (!!params && !params.value) {
        this.myChart.dispatchAction({
          type: "downplay",
        });
      }

      // 当上一个显示高亮是  移动鼠标 只显示当前高亮
      this.myChart.dispatchAction({
        type: "downplay",
        seriesIndex: 0,
        dataIndex: params
          ? params.dataIndex === 0
            ? this.dataResource.length - 1
            : 0
          : this.currentIndex, // 取消高亮
      });

      if (this.tooltipInterval) {
        // 清除之前的高亮显示
        clearInterval(this.tooltipInterval);
        this.tooltipInterval = null;
      }
    },
    // 高亮显示区域
    highlightArea() {
      let index = this.currentIndex;
      // 清除之前的高亮显示
      this.myChart.dispatchAction({
        type: "downplay",
        seriesIndex: 0,
        dataIndex: index === 0 ? this.dataResource.length - 1 : index - 1, // 取消高亮
      });

      // 使用dispatchAction来高亮显示对应的区域
      this.myChart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: index,
      });

      //showTip展示相应提示框
      this.myChart.dispatchAction({
        type: "showTip", // 提示框
        seriesIndex: 0,
        name: this.dataResource[index]?.name, // 第几高亮
      });

      // 使用dispatchAction来展示对应的提示框
      //如果是最后一个了就从头开始
      if (index === this.dataResource.length - 1) index = 0;
      else index++;
      this.currentIndex = index;
    },
  },
};
</script>

<style scoped>
#china_map_box {
  min-height: 730px;
  width: 100%;
  margin-top: 20px;
}

#china_map_box #china_map {
  min-height: 730px;
}

#china_map .tooltip_style {
  line-height: 1.7;
  overflow: hidden;
}

#china_map .tooltip_left {
  float: left;
}

#china_map .tooltip_right {
  float: right;
  margin-left: 20px;
}
</style>
